import { autoserializeAs, autoserializeAsArray, inheritSerialization } from 'dcerialize';

import { CourseTypeDurations } from '../utils/enums';
import { CourseModule } from './course-module';
import { CoursePrice } from './price';
import { RestItemList } from './rest-item';
import { TableFilter } from './table-filter';

/* eslint-disable @typescript-eslint/naming-convention */
export const currencyMapping: Record<string, string> = {
  ES: '€',
  AR: '$',
  BO: 'Bs',
  BR: 'R$',
  CL: '$',
  CO: '$',
  CR: '$',
  EC: '$',
  SV: '$',
  US: '$',
  GT: 'Q',
  GQ: '€',
  HN: 'L',
  IT: '€',
  MX: '$',
  NI: 'C$',
  PA: '$',
  PY: '₲',
  PE: 'S/',
  PT: '€',
  PR: '$',
  DO: 'RD$ ',
  UY: '$U',
  VE: '$'
};

/* eslint-enable @typescript-eslint/naming-convention */

export class Course {
  /**
   * _id course
   */
  @autoserializeAs(() => Number) _id: number;

  /**
   * ID Simo
   */
  @autoserializeAs(() => Number) idSimo: number;

  /**
   * Title seo
   */
  @autoserializeAs(() => String) titleSeo?: string;

  /**
   * Course duration in hours
   */
  @autoserializeAs(() => Number) duration?: number;

  /**
   * Course level
   */
  @autoserializeAs(() => String) level?: string;

  /**
   * Course categories
   */
  @autoserializeAsArray(() => Number, () => Number, 'categories') category: number[];

  /**
   * Course type
   */
  @autoserializeAs(() => Number) type?: number;

  /**
   * img url
   */
  @autoserializeAs(() => String) img?: string;

  /**
   * video url
   */
  @autoserializeAs(() => String) video?: string;

  /**
   * Inscription period status
   */
  @autoserializeAs(() => String) inscription?: string;

  /**
   * Course free
   */
  @autoserializeAs(() => Boolean) free?: boolean;

  /**
   * Modality
   */
  @autoserializeAs(() => String) modality?: string;

  /**
   * Institution
   */
  @autoserializeAs(() => CourseInstitution) institution: CourseInstitution;

  /**
   * Content
   */
  @autoserializeAs(() => CourseContent) content?: CourseContent;

  /**
   * Course name
   */
  @autoserializeAs(() => String) name: string;

  /**
   * Course tags
   */
  @autoserializeAsArray(() => String) tags?: string[];

  /**
   * Course price
   */
  @autoserializeAsArray(() => CoursePrice) price?: CoursePrice[];

  /**
   * Is marked as highlighted
   */
  @autoserializeAs(() => Boolean) highlighted?: boolean;

  /**
   * Is marked as popular
   */
  @autoserializeAs(() => Boolean) popular?: boolean;

  /**
   * Description
   */
  @autoserializeAs(() => String) description?: string;

  /**
   * Course details information
   */
  @autoserializeAs(() => CourseDetails) courseDetails?: CourseDetails;

  /**
   * Course searches
   */
  @autoserializeAs(() => Number) searches?: number;

  /**
   * Number of people who have scored with stars
   */
  @autoserializeAs(() => Number) totalRatings: number;

  /**
   * Course stars
   */
  @autoserializeAs(() => Number) rating?: number;

  /**
   * Course timesSaved
   */
  @autoserializeAs(() => Number) timesSaved?: number;

  /**
   * Course users
   */
  @autoserializeAs(() => Number) users?: number;

  /**
   * Course name
   */
  @autoserializeAs(() => String) code?: string;
  /**
   * Course brand
   */
  @autoserializeAs(() => String) brand?: string;

  /**
   * Course campaign
   */
  @autoserializeAs(() => String) campaign?: string;

  /**
   * ID to relate a child course to it parent
   */
  @autoserializeAsArray(() => Number) parentIdsSimo?: number[];

  /**
   * Whether the course has free content
   */
  @autoserializeAs(() => Boolean) hasFreeContent?: boolean;

  /**
   * Whether course has other courses as children
   */
  @autoserializeAs(() => Boolean) hasChildren?: boolean;

  /**
   * Whether course is a sheet or not
   */
  @autoserializeAs(() => Boolean) isCourseSheet?: boolean;

  /**
   * Course duration type (if None is hours)
   */
  @autoserializeAs(() => String, 'duration_type') durationType?: CourseTypeDurations;

  constructor(
    _id = 0,
    idSimo: number,
    name: string,
    titleSeo?: string,
    duration?: number,
    level?: string,
    category = [],
    type?: number,
    img?: string,
    video?: string,
    inscription?: string,
    free?: boolean,
    modality?: string,
    institution: CourseInstitution = new CourseInstitution(),
    content?: CourseContent,
    isCourseSheet?: boolean,
    tags: string[] = [],
    price?: CoursePrice[],
    highlighted?: boolean,
    popular?: boolean,
    description?: string,
    courseDetails: CourseDetails = new CourseDetails(),
    searches?: number,
    totalRatings = 0,
    rating = 0,
    timesSaved?: number,
    users?: number,
    code?: string,
    brand?: string,
    campaign?: string,
    parentIdsSimo = [],
    hasFreeContent?: boolean,
    hasChildren?: boolean,
    durationType?: CourseTypeDurations
  ) {
    this._id = _id;
    this.idSimo = idSimo;
    this.titleSeo = titleSeo;
    this.duration = duration;
    this.level = level;
    this.category = category;
    this.type = type;
    this.img = img;
    this.video = video;
    this.inscription = inscription;
    this.free = free;
    this.modality = modality;
    this.institution = institution;
    this.content = content;
    this.name = name;
    this.tags = tags;
    this.price = price;
    this.highlighted = highlighted;
    this.popular = popular;
    this.description = description;
    this.courseDetails = courseDetails;
    this.searches = searches;
    this.totalRatings = totalRatings;
    this.rating = rating;
    this.timesSaved = timesSaved;
    this.users = users;
    this.code = code;
    this.brand = brand;
    this.campaign = campaign;
    this.parentIdsSimo = parentIdsSimo;
    this.hasFreeContent = hasFreeContent;
    this.hasChildren = hasChildren;
    this.isCourseSheet = isCourseSheet;
    this.durationType = durationType;
  }

  /**
   * Method to know if the course is free
   */
  isFree(): boolean {
    return this.free === true;
  }

  /**
   * Method to know if the course has been published from backoffice
   */
  isFromBackoffice(): boolean {
    return !!this.institution.customerId;
  }

  /**
   * Method to know price in EUR
   */
  getPrice(countryCode = 'ES'): string {
    let value = this.price?.filter((p) => p.country === countryCode).map((p) => p.price);
    let currency = currencyMapping[countryCode] || '€';
    if (value?.length === 0) {
      value = this.price?.filter((p) => p.country === 'ES').map((p) => p.price);
      currency = '€';
    }

    return value + currency;
  }

  /**
   * Method to get the stars of the course
   */
  get computedStars(): number {
    if (this.totalRatings != undefined && this.rating != undefined) {
      return this.totalRatings === 0 ? 0 : Number((this.rating / this.totalRatings).toFixed(1));
    }

    return 0;
  }

  getCourseDurationType(): string {
    if (this.durationType && this.durationType !== CourseTypeDurations.HOURS) {
      if (this.durationType === CourseTypeDurations.MINUTES) {
        return 'm';
      }

      return 'durationType.' + this.durationType;
    }

    return 'h';
  }
}

export class CourseFilter {
  /**
   * Levels
   */
  @autoserializeAsArray(() => String) levels?: string[];

  /**
   * Types
   */
  @autoserializeAsArray(() => Number) types?: number[];

  /**
   * Categories
   */
  @autoserializeAsArray(() => Number) categories?: number[];

  /**
   * Course institution
   */
  @autoserializeAsArray(() => Number) institutions?: number[];

  /**
   * popular courses
   */
  @autoserializeAs(() => Boolean) popular?: boolean;

  /**
   * Highlighted courses
   */
  @autoserializeAs(() => Boolean) highlighted?: boolean;

  /**
   * Highlighted courses
   */
  @autoserializeAs(() => Boolean) free?: boolean;

  /**
   * Mooc course
   */
  @autoserializeAs(() => Boolean) mooc?: boolean;

  /**
   * finalized course
   */
  @autoserializeAs(() => Boolean) finalized?: boolean;

  /**
   * Duration
   */
  @autoserializeAsArray(() => String) durations?: string[];

  /**
   * Whether filter by root courses
   */
  @autoserializeAs(() => Boolean) root?: boolean;

  /**
   * Parent code
   */
  @autoserializeAsArray(() => Number) parentIdsSimo?: number[];

  /**
   * Whether filter by courses that are not being processed
   */
  @autoserializeAs(() => Boolean) notProcessing?: boolean;

  constructor(
    levels = [],
    types = [],
    categories = [],
    institutions = [],
    popular?: boolean,
    highlighted?: boolean,
    free?: boolean,
    durations = [],
    mooc?: boolean,
    finalized?: boolean,
    root = false,
    parentIdsSimo = [],
    notProcessing?: boolean
  ) {
    this.levels = levels;
    this.types = types;
    this.categories = categories;
    this.institutions = institutions;
    this.popular = popular;
    this.highlighted = highlighted;
    this.free = free;
    this.durations = durations;
    this.mooc = mooc;
    this.finalized = finalized;
    this.root = root;
    this.parentIdsSimo = parentIdsSimo;
    this.notProcessing = notProcessing;
  }
}

/**
 * Class to serialize the object filter as CourseFilter
 */
@inheritSerialization(() => TableFilter)
export class CourseParametersFilter extends TableFilter<CourseFilter> {
  /**
   * CourseFilter to get the data required
   */
  @autoserializeAs(() => CourseFilter) filter: CourseFilter;

  constructor(filter: CourseFilter = new CourseFilter(), query = '', limit = 9, page = 1, sort = '') {
    super(limit, page, query, sort);
    this.filter = filter;
  }
}

export class CourseReduced {
  /**
   * _id course
   */
  @autoserializeAs(() => Number) _id!: number;

  /**
   * ID Simo
   */
  @autoserializeAs(() => Number) idSimo: number;

  /**
   * Title seo
   */
  @autoserializeAs(() => String) titleSeo?: string;

  /**
   * Course duration (in hours if duration_type is None)
   */
  @autoserializeAs(() => Number) duration?: number;

  /**
   * Course level
   */
  @autoserializeAs(() => String) level?: string;

  /**
   * Course categories
   */
  @autoserializeAsArray(() => Number, () => Number, 'categories') category: number[];

  /**
   * Course type
   */
  @autoserializeAs(() => Number) type?: number;

  /**
   * img url
   */
  @autoserializeAs(() => String) img: string;

  /**
   * video url
   */
  @autoserializeAs(() => String) video?: string;

  /**
   * Inscription period status
   */
  @autoserializeAs(() => String) inscription?: string;

  /**
   * Course free
   */
  @autoserializeAs(() => Boolean) free?: boolean;

  /**
   * Modality
   */
  @autoserializeAs(() => String) modality?: string;

  /**
   * Content
   */
  @autoserializeAs(() => CourseContent) content?: CourseContent;

  /**
   * Course name
   */
  @autoserializeAs(() => String) name: string;

  /**
   * Course tags
   */
  @autoserializeAsArray(() => String) tags: string[];

  /**
   * Course name
   */
  @autoserializeAs(() => String) code?: string;

  /**
   * Course price
   */
  @autoserializeAsArray(() => CoursePrice) price: CoursePrice[];

  /**
   * Is marked as highlighted
   */
  @autoserializeAs(() => Boolean) highlighted?: boolean;

  /**
   * Number of modules
   */
  @autoserializeAs(() => Number) modules?: number;

  /**
   * Is marked as popular
   */
  @autoserializeAs(() => Boolean) popular?: boolean;

  /**
   * Description
   */
  @autoserializeAs(() => String) description?: string;

  /**
   * Course details information
   */
  @autoserializeAs(() => CourseDetails) courseDetails: CourseDetails;

  /**
   * Course searches
   */
  @autoserializeAs(() => Number) searches?: number;

  /**
   * Number of people who have scored with stars
   */
  @autoserializeAs(() => Number) totalRatings: number;

  /**
   * Course stars
   */
  @autoserializeAs(() => Number) rating: number;

  /**
   * Course timesSaved
   */
  @autoserializeAs(() => Number) timesSaved?: number;

  /**
   * Course users
   */
  @autoserializeAs(() => Number) users?: number;

  /**
   * Course brand
   */
  @autoserializeAs(() => String) brand?: string;
  /**
   * Course campaign
   */
  @autoserializeAs(() => String) campaign?: string;

  /**
   * Institution
   */
  @autoserializeAs(() => CourseInstitution) institution: CourseInstitution;

  /**
   * Whether course has other courses as children
   */
  @autoserializeAs(() => Boolean) hasChildren?: boolean;

  /**
   * Whether course is a sheet or not
   */
  @autoserializeAs(() => Boolean) isCourseSheet?: boolean;

  /**
   * IdSeo from course sheets
   */
  @autoserializeAs(() => String) idSeo?: string;

  /**
   * Course duration type (if None is hours)
   */
  @autoserializeAs(() => String, 'duration_type') durationType?: CourseTypeDurations;

  constructor(
    id = 0,
    idSimo: number,
    name: string,
    img = 'assets/university/course-image-placeholder.png',
    titleSeo?: string,
    duration?: number,
    level?: string,
    category = [],
    type?: number,
    video?: string,
    inscription?: string,
    free?: boolean,
    modality?: string,
    price: CoursePrice[] = [],
    content?: CourseContent,
    tags: string[] = [],
    highlighted?: boolean,
    popular?: boolean,
    description?: string,
    courseDetails: CourseDetails = new CourseDetails(),
    searches?: number,
    totalRatings = 0,
    rating = 0,
    timesSaved?: number,
    users?: number,
    code?: string,
    brand?: string,
    campaign?: string,
    institution: CourseInstitution = new CourseInstitution(),
    hasChildren?: boolean,
    isCourseSheet?: boolean,
    idSeo?: string,
    durationType?: CourseTypeDurations
  ) {
    this._id = id;
    this.idSimo = idSimo;
    this.titleSeo = titleSeo;
    this.duration = duration;
    this.level = level;
    this.category = category;
    this.type = type;
    this.img = img;
    this.video = video;
    this.inscription = inscription;
    this.free = free;
    this.modality = modality;
    this.price = price;
    this.content = content;
    this.name = name;
    this.tags = tags;
    this.highlighted = highlighted;
    this.popular = popular;
    this.description = description;
    this.courseDetails = courseDetails;
    this.searches = searches;
    this.totalRatings = totalRatings;
    this.rating = rating;
    this.timesSaved = timesSaved;
    this.users = users;
    this.code = code;
    this.brand = brand;
    this.campaign = campaign;
    this.institution = institution;
    this.hasChildren = hasChildren;
    this.isCourseSheet = isCourseSheet;
    this.idSeo = idSeo;
    this.durationType = durationType;
  }

  /**
   * Method to know if the course is free
   */
  isFree(): boolean {
    return !!this.free;
  }

  /**
   * Method to know if the course has been published from backoffice
   */
  isFromBackoffice(): boolean {
    return !!this.institution.customerId;
  }

  /**
   * Method to know if the course has a logo at least
   */
  hasLogo(): boolean {
    return !!this.institution && !!this.institution.logo && this.institution.logo.length > 0;
  }

  isUniversityAcredited(): boolean {
    return !!this.institution && !!this.institution.ectsCredits && this.institution.ectsCredits > 0;
  }

  getPrice(countryCode = 'ES'): string {
    let value = this.price.filter((p) => p.country === countryCode).map((p) => p.price);
    let currency = currencyMapping[countryCode] || '€';
    if (value.length === 0) {
      value = this.price.filter((p) => p.country === 'ES').map((p) => p.price);
      currency = '€';
    }

    return value + currency;
  }

  get computedStars(): number {
    if (this.totalRatings != undefined && this.rating != undefined) {
      return this.totalRatings === 0 ? 0 : Number((this.rating / this.totalRatings).toFixed(1));
    }

    return 0;
  }

  getCourseDurationType(): string {
    if (this.durationType && this.durationType !== CourseTypeDurations.HOURS) {
      if (this.durationType === CourseTypeDurations.MINUTES) {
        return 'm';
      }

      return 'durationType.' + this.durationType;
    }

    return 'h';
  }
}

/**
 * Class that represents a course reduced list response
 */
export class CourseReducedList extends RestItemList<CourseReduced> {
  /**
   * Course list
   */
  @autoserializeAsArray(() => CourseReduced) items: CourseReduced[];

  /**
   * Number of courses
   */
  @autoserializeAs(() => Number) total: number;

  constructor(items: CourseReduced[] = [], total = 0) {
    super();
    this.items = items;
    this.total = total;
  }
}

class CourseDetails {
  /**
   * Course audience
   */
  @autoserializeAs(() => String) audience?: string;

  /**
   * Course degree description
   */
  @autoserializeAs(() => String) degreeDescription?: string;

  /**
   * Course degree description
   */
  @autoserializeAs(() => String) methodology?: string;

  /**
   * Course objectives
   */
  @autoserializeAs(() => String) objectives?: string;

  /**
   * Course professional opportunities
   */
  @autoserializeAs(() => String) professionalOpportunities?: string;

  /**
   * Course what prepares you for
   */
  @autoserializeAs(() => String) whatPreparesYouFor?: string;

  /**
   * Course transversal competences
   */
  @autoserializeAs(() => String) transversalCompetences?: string;

  /**
   * Course specific competences
   */
  @autoserializeAs(() => String) specificCompetences?: string;

  constructor(
    audience?: string,
    degreeDescription?: string,
    methodology?: string,
    objectives?: string,
    professionalOpportunities?: string,
    whatPreparesYouFor?: string,
    transversalCompetences?: string,
    specificCompetences?: string
  ) {
    this.audience = audience;
    this.degreeDescription = degreeDescription;
    this.methodology = methodology;
    this.objectives = objectives;
    this.professionalOpportunities = professionalOpportunities;
    this.whatPreparesYouFor = whatPreparesYouFor;
    this.transversalCompetences = transversalCompetences;
    this.specificCompetences = specificCompetences;
  }
}

export class CourseInstitution {
  /**
   * Institution ID
   */
  @autoserializeAs(() => Number) _id!: number;
  /**
   * Course logo
   */
  @autoserializeAsArray(() => String) logo?: string[];

  /**
   * Institution name
   */
  @autoserializeAs(() => String) name?: string;

  /**
   * Course credits ects
   */
  @autoserializeAs(() => Number) ectsCredits: number;

  @autoserializeAs(() => Number) customerId?: number;

  constructor(logo?: string[], name?: string, ectsCredits = 0, customerId?: number) {
    this.logo = logo;
    this.name = name;
    this.ectsCredits = ectsCredits;
    this.customerId = customerId;
  }
}

export class CourseContent {
  /**
   * Temary, list of modules
   */
  @autoserializeAsArray(() => CourseModule) course: CourseModule[];

  /**
   * Course exams number
   */
  @autoserializeAs(() => Number) exams: number;

  /**
   * Course videos number
   */
  @autoserializeAs(() => String) videos: string;

  /**
   * Course self evaluation
   */
  @autoserializeAs(() => Number) selfEvaluation: number;

  constructor(course: CourseModule[], exams: number, videos: string, selfEvaluation: number) {
    this.course = course;
    this.exams = exams;
    this.videos = videos;
    this.selfEvaluation = selfEvaluation;
  }
}

export class AnalyticsCourseItem {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @autoserializeAs(() => String) item_id: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @autoserializeAs(() => String) item_name: string;
  @autoserializeAs(() => Number) price: number;
  @autoserializeAs(() => String) currency: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @autoserializeAs(() => String) item_brand: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @autoserializeAs(() => String) item_category: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @autoserializeAs(() => String) item_category2: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @autoserializeAs(() => String) item_category3: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @autoserializeAs(() => String) item_variant: string;
  @autoserializeAs(() => Number) quantity: number;

  constructor(
    // eslint-disable-next-line @typescript-eslint/naming-convention
    item_name: string,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    item_id: string,
    price = 0,
    currency = '',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    item_brand = '',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    item_category = '',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    item_category2 = '',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    item_category3 = '',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    item_variant = '',
    quantity = 1
  ) {
    this.item_name = item_name;
    this.item_id = item_id;
    this.price = price;
    this.currency = currency;
    this.item_brand = item_brand;
    this.item_category = item_category;
    this.item_category2 = item_category2;
    this.item_category3 = item_category3;
    this.item_variant = item_variant;
    this.quantity = quantity;
  }
}

export class AnalyticsCourse {
  /**
   * Transaction id
   *
   * Empty by default
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @autoserializeAs(() => String, 'transaction_id') transaction_id: string;
  @autoserializeAs(() => String) affiliation: string;
  @autoserializeAs(() => Number) value: number;
  @autoserializeAs(() => Number) tax: number;
  @autoserializeAs(() => Number) shipping: number;
  @autoserializeAs(() => String) currency: string;
  @autoserializeAs(() => String) coupon: string;
  @autoserializeAsArray(() => AnalyticsCourseItem) items: AnalyticsCourseItem[];

  constructor(
    // eslint-disable-next-line @typescript-eslint/naming-convention
    transaction_id = '',
    affiliation = '',
    value = 0,
    tax = 0,
    shipping = 0,
    currency = '',
    coupon = '',
    items: AnalyticsCourseItem[] = []
  ) {
    this.transaction_id = transaction_id;
    this.affiliation = affiliation;
    this.value = value;
    this.tax = tax;
    this.shipping = shipping;
    this.currency = currency;
    this.coupon = coupon;
    this.items = items;
  }
}

export class PaymentUrl {
  @autoserializeAs(() => String) url?: string;
}
